import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "utils/libs/İ18next/locales/en/en.json";
import es from "utils/libs/İ18next/locales/es/es.json";

const resources = {
  ...en,
  ...es,
};

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    // This is a trick to ignore 'accessing an object - but returnObjects options is not enabled!' warning
    returnedObjectHandler: () => "",
    resources,
  });

export default i18n;
