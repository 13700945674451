import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { H6 } from "components/Atoms/Headings/Headings";
import Spinner from "components/Atoms/Spinner/Spinner";

const Loader = ({ isLoading, children, background }) => {
  const { t } = useTranslation("components", {
    keyPrefix: "loader",
  });

  return isLoading ? (
    <LoadingContainer background={background}>
      <Spinner />
      <H6 uiTheme={"white"}>{t("loadingText")}</H6>
    </LoadingContainer>
  ) : (
    children
  );
};

export default Loader;

const LoadingContainer = styled.div`
  align-items: center;
  background: ${({ background }) =>
    background ? background : "var(--color-primary-gradient)"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 100vh;
  > * {
    margin-right: 8px;
  }
  &:last-child {
    margin-right: 0;
  }
`;
