import { createContext, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { useHistory } from "react-router-dom";
import Loader from "components/Blocks/Loader/Loader";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const {
    isAuthenticated: isAuthenticatedByAuth0,
    user,
    getAccessTokenSilently,
    logout,
    isLoading,
  } = useAuth0();
  const access = localStorage.getItem("access") || "";
  const refresh = localStorage.getItem("refresh") || "";
  const [authState, setAuthState] = useState({
    access,
    refresh,
  });
  // const [isAuthenticatedByServer, setIsAuthenticatedByServer] = useState(
  //   checkForTokens()
  // );

  const getAuth0Token = useCallback(async () => {
    const token = await getAccessTokenSilently().then((token) => token);
    localStorage.setItem("access", token);

    setAuthState((prevState) => ({
      ...prevState,
      access: token,
    }));

    return token;
  }, [getAccessTokenSilently]);

  useEffect(() => {
    user && getAuth0Token();

    return () => setAuthState({});
  }, [user, getAuth0Token]);

  /**
   * As we have decided to transition to using Auth0 for authentication,
   * we will temporarily comment out our existing authentication routes.
   * This way, we can easily revert to them if the new flow does not work
   * as expected.
   * TODO: Remove the old login files if auth0 authentication flow
   * works fine
   * Related JIRA Issue: https://correlation-one.atlassian.net/browse/FEL-404
   */
  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     if (!isAuthenticatedByServer && checkForTokens()) {
  //       setIsAuthenticatedByServer(true);
  //     } else if (isAuthenticatedByServer && !checkForTokens()) {
  //       setIsAuthenticatedByServer(false);
  //       queryClient.clear();
  //     }
  //   });

  //   return () => {
  //     unlisten();
  //     queryClient.clear();
  //   };
  // }, [isAuthenticatedByServer, queryClient, history]);

  const setAuthInfo = ({ access = "", refresh = "" }) => {
    access && localStorage.setItem("access", access);
    refresh && localStorage.setItem("refresh", refresh);

    setAuthState({
      access,
      refresh,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    Sentry.setUser(null);
    if (user) {
      logout({
        returnTo: window.location.origin,
      });
    } else {
      history.push("/login");
    }
  };

  return (
    <Loader isLoading={isLoading}>
      <Provider
        value={{
          auth0User: user,
          authState,
          getAuth0Token,
          isAuthenticatedByAuth0,
          // isAuthenticatedByServer,
          authLogout: handleLogout,
          setAuthState: (authInfo) => setAuthInfo(authInfo),
        }}
      >
        {children}
      </Provider>
    </Loader>
  );
};

export { AuthContext, AuthProvider };

// const checkForTokens = () => {
//   return !(!localStorage.getItem("access") && !localStorage.getItem("refresh"));
// };
