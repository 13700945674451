import { BrowserTracing } from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { FallbackComponent } from "components/Blocks/ErrorBoundary/FallbackComponent";
import { version } from "../../../../package.json";

Sentry.init({
  dsn: "https://e08dd52740ea476ea826b031098422d3@o1174160.ingest.sentry.io/6335799",
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ["error"],
    }),
    new Replay({
      sessionSampleRate: 0,
      errorSampleRate: 1.0,
      maskAllText: false,
    }),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.NODE_ENV === "production",
  release: version,
  tunnel: process.env.REACT_APP_API_URL + "sentry",
  tracesSampleRate: 0.5,
});

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
