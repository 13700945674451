import axios from "axios";

const AxiosController = (
  axiosConfig,
  accessToken,
  getAuth0Token,
  authLogout
) => {
  const axiosInstance = axios.create(axiosConfig);

  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config._retry) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error?.config;

      // Did a request, failed with 401 (token expired), we should do the
      // request again with a new token
      if (error?.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await getAuth0Token();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      }

      // Already retried the request and it's still returning 401
      // we should logout the user (credentials not valid)
      if (error?.response.status === 401 && originalRequest._retry) {
        // eslint-disable-next-line no-console
        console.error("Access denied, login required");
        authLogout();
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export { AxiosController };
