import styled, { css } from "styled-components/macro";

const baseStyles = css`
  color: var(--color-neutral-23);
  font-family: var(--font-family);
  font-weight: var(--weight-regular);
  margin: 0;
  padding: 0;
`;

const overrideStyles = css`
  color: ${({ color, uiTheme }) =>
    color ? color : uiTheme && `var(--color-${uiTheme}-default)`};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};
`;

export const Subtitle1 = styled.p`
  ${baseStyles}
  font-size: 22px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-medium)`};
  letter-spacing: 0em;
  line-height: 34px;
  ${overrideStyles}
`;

export const Subtitle2 = styled.p`
  ${baseStyles}
  font-size: 18px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-bold)`};
  letter-spacing: 0em;
  line-height: 26px;
  ${overrideStyles}
`;

export const Label = styled.p`
  ${baseStyles}
  font-size: 12px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-bold)`};
  letter-spacing: 0.02em;
  line-height: 18px;
  ${overrideStyles}
`;

export const Body = styled.p`
  ${baseStyles}
  font-size: 16px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-bold)`};
  letter-spacing: 0em;
  line-height: 24px;
  ${overrideStyles}
`;

export const BodySmall = styled.p`
  ${baseStyles}
  font-size: 14px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-medium)`};
  letter-spacing: 0em;
  line-height: 20px;
  ${overrideStyles}
`;

export const Caption = styled.p`
  ${baseStyles}
  font-size: 12px;
  font-weight: ${({ isBold }) => isBold && `var(--weight-bold)`};
  letter-spacing: 0em;
  line-height: 18px;
  ${overrideStyles}
`;
