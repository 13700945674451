import { Suspense, useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import styled from "styled-components/macro";
import useAuthContext from "context/AuthContext/useAuthContext";
import { FetchProvider } from "context/FetchContext/FetchContext";
import { LDStatusProvider } from "context/LDStatusContext/LDStatusContext";
import history from "utils/constants/history";
import { lazyWithRetry } from "utils/helpers/lazyWithRetry";

const AuthedApp = lazyWithRetry(() => import("pages/App/AuthedApp"));
const UnauthedApp = lazyWithRetry(() => import("pages/App/UnauthedApp"));

const App = () => {
  const { isAuthenticatedByAuth0 } = useAuthContext();
  const { migratingClientDomains } = useFlags();

  useEffect(() => {
    // Remove this when migration is finished
    if (migratingClientDomains) {
      const redirectToBaseUrl = (baseUrl) => {
        const currentUrl = new URL(window.location.href);
        if (currentUrl.origin !== baseUrl) {
          window.location.href = new URL(
            currentUrl.pathname + currentUrl.search + currentUrl.hash,
            baseUrl
          );
        }
      };

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        redirectToBaseUrl("https://training.correlation-one.com");
      }

      if (process.env.REACT_APP_ENVIRONMENT === "nprd") {
        redirectToBaseUrl("https://training.nprd.correlation-one.com");
      }
    }
  }, [migratingClientDomains]);

  if (!isAuthenticatedByAuth0) {
    localStorage.setItem("deeplink", window.location.pathname);
  }

  if (process.env.REACT_APP_ENVIRONMENT === "ephemeral") {
    const url = localStorage.getItem("training-client-eph-redirect") || "";
    const searchParams = new URLSearchParams(history.location.search);
    const urlSearchParams = url.includes("?")
      ? new URLSearchParams(url.split("?")[1])
      : [];
    urlSearchParams.forEach((value, key) => searchParams.set(key, value));
    history.push({
      pathname: url.split("?")?.[0] || url,
      search: searchParams.toString(),
    });
    localStorage.removeItem("training-client-eph-redirect");
  }

  return (
    <Suspense fallback={<FallbackScreen />}>
      <FetchProvider>
        <LDStatusProvider>
          {isAuthenticatedByAuth0 ? <AuthedApp /> : <UnauthedApp />}
        </LDStatusProvider>
      </FetchProvider>
    </Suspense>
  );
};

export default App;

const FallbackScreen = styled.main`
  background: var(--color-primary-gradient);
  height: 100vh;
  width: 100vw;
`;
